import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Banner from '../components/Banner'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import SEO from '../components/SEO'

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      excerpt,
      frontmatter: { title, category, image, date, embeddedImages, slug },
      body,
    },
  } = data

  return (
    <>
      <Layout>
        <SEO title={`${title}`} description={`${excerpt}`} />
        <Wrapper>
          {/* post info */}
          <article className="post-body">
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className="main-img"
            />
            <div className="post-info">
              <span>{category}</span>
              <h2>{title}</h2>
              <p>{date}</p>
              <div className="underline"></div>
            </div>
            <MDXRenderer embeddedImages={embeddedImages}>{body}</MDXRenderer>
          </article>
          <article>
            <Banner />
          </article>
        </Wrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      frontmatter {
        title
        category
        date(formatString: "YYYY年MM月DD日")
        readTime
        slug
        embeddedImages {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
      body
    }
  }
`

const Wrapper = styled.section`
  width: 85vw;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 4rem;

  .post-info {
    margin: 2rem 0 4rem 0;
    text-align: center;
    span {
      background: var(--clr-grey-10);
      color: var(--clr-grey-5);
      border-radius: var(--radius);
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
      letter-spacing: var(--spacing);
    }
    h2 {
      margin: 1.25rem 0;
      font-weight: 400;
    }
    p {
      color: var(--clr-grey-5);
    }
    .underline {
      width: 5rem;
      height: 1px;
      background: var(--clr-grey-9);
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 992px) {
    & {
      width: 92vw;
    }
    .main-img {
      width: 75%;
      display: block;
      margin: 0 auto;
    }
  }
  @media (min-width: 1170px) {
    & {
      display: grid;
      grid-template-columns: 1fr 200px;
      column-gap: 4rem;
    }
  }
`

export default PostTemplate
